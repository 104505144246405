import { Injectable } from "@angular/core";
import { CustomHttpClient } from "./http.service";

@Injectable()
export class TicketService {
  constructor(private http: CustomHttpClient) {}
  getTickets(id, email) {
    return this.http.get("/ticket/" + id + "/" + email);
  }

  addTickets(data) {
    return this.http.post("/ticket", data);
  }

  contact(data) {
    return this.http.post("/order/contact", data);
  }

  updateTickets(data) {
    return this.http.put("/ticket",data);
  }

  getTicketHistory(id){
    return this.http.get("/tickethistory/all/"+id);
  }

  orderSave(data){
    return this.http.post("/order/ccpayment",data);
  }
  getDomain(){
    return this.http.get("/order/domain");
  }

  public upload(formData) {

    return this.http.post("/ticket/filesave" ,formData);
  }

  public SaveFile(formData) {

    return this.http.post("/ticket/filetodb" ,formData);
  }
  public DeleteFile(formData) {

    return this.http.get("/ticket/filedel/"+formData);
  }
  public getFile(formData) {

    return this.http.get("/ticket/files/"+ formData);
  }


}
