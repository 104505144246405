import { Injectable } from "@angular/core";
import {
  Http,
  Headers,
  Response,
  URLSearchParams,
  RequestOptions,
  RequestMethod
} from "@angular/http";
import { StorageService } from "./storage.service";
import { map } from "rxjs/operators";
import { Globals } from "./globalsss";

@Injectable()
export class CustomHttpClient {
  config: any;
  BaseURL: string;
  glo: Globals;
  constructor(
    private http: Http,
    private storage: StorageService,
    private gl: Globals
  ) {
    this.BaseURL = "https://api.isourcecloud.com/api";
    //https://boxoffice.conciergetixs.com
    //

    // this.BaseURL = "https://api.ticketdev.com/api";
    this.glo = gl;
  }

  createAuthorizationHeader(headers: Headers) {
    // let currentUser = this.storage.get("user");
    // headers.append("Authorization", "Bearer " + this.glo.User.userPassword);
    ////console.log( "App Url",window.origin);
    // if(!window.origin.startsWith("http://localhost"))
    // this.BaseURL=  window.origin.replace("boxoffice","api")+"/api";
    // headers.append("X-L", this.glo.SelectedLocation.identity);
    // headers.append("X-I", this.glo.UserId);
    // headers.append("X-Name", this.glo.User.userName);
    headers.append("Content-Type", "application/json; ");
  }

  defualt(headers: Headers) {
    headers.append("Content-Type", "application/json;");
  }

  get(url) {
   // this.gl.showLoader = true;
    var url1 = this.BaseURL + url;
    // //console.log(this.gl);
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    //   Processing("#Processing","block");
    return this.http
      .get(url1, {
        headers: headers
      })
      .pipe(
        map((data: Response) => {
         
          return data.json();
        })
      );
  }
  delete(url) {
  
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    return this.http
      .delete(this.BaseURL + url, {
        headers: headers
      })
      .pipe(
        map(data => {
          //this.gl.showLoader = false;
          return data.json();
        })
      );
  }
  post(url, data) {
   // this.gl.showLoader = true;
    //  //console.log(this.gl);
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    return this.http
      .post(this.BaseURL + url, data, {
        headers: headers
      })
      .pipe(
        map(data => {
         // this.gl.showLoader = false;
          //console.log(this.gl);
          return data.json();
        })
      );
  }
  postnonHeader(url, data) {
    if (!window.origin.startsWith("https://api.isourcecloud.com"))
      this.BaseURL = window.origin.replace("boxoffice", "api") + "/api";
   // this.gl.showLoader = true;
    let headers = new Headers();
    this.defualt(headers);
    return this.http.post(this.BaseURL + url, data, { headers: headers }).pipe(
      map(data => {
     //   this.gl.showLoader = false;
        return data.json();
      })
    );
  }
  put(url, data) {
   // this.gl.showLoader = true;
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    return this.http
      .put(this.BaseURL + url, data, {
        headers: headers
      })
      .pipe(
        map(data => {
          //this.gl.showLoader = false;
          return data.json();
        })
      );
  }
}
