import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { Routing } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AppConfig } from "./app.config";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { MasterModule } from "./layout/master/master.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// import { HttpClient } from "@angular/commo";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Http, HttpModule } from "@angular/http";
import { MatSharedModule } from './shared/mat.module';
import { CustomHttpClient, Globals, StorageService } from './services';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    Routing,MasterModule,
    ReactiveFormsModule,
    MatSharedModule,
    HttpModule,
    BrowserAnimationsModule,
    FormsModule,
      ],
  providers: [
    AppConfig,
    CustomHttpClient,
    Globals,
    StorageService
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy
    // }
  ],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
