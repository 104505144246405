import { Injectable } from "@angular/core";
import { CustomHttpClient } from "./http.service";

declare var swal: any;

@Injectable()
export class AuthService {
  constructor(private http: CustomHttpClient   ) {}
  ValidateUser(data) {
    let options = {}; // new RequestOptions({ method: RequestMethod.Post, headers: headers });
    return this.http.postnonHeader("/user/token", data);
  }

  getCategories() {
    return this.http.get("/user");
  }
 
}
