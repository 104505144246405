import { Injectable } from "@angular/core";

@Injectable()
export class Globals {
  ByUserName: any;
  IsAdminUser: boolean = false;
  Ticket: any;
  Tickets: any;
  Product:any;
  pad(num: number): string {
    let s = num + "";
    while (s.length < 8) s = "0" + s;
    return s;
  }
}
