import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MasterComponent } from './master.component';
import { CommonModule } from '@angular/common';
import { MatSharedModule } from 'src/app/shared/mat.module';
import { MDBBootstrapModule } from 'angular-bootstrap-md';


export const routes: Routes = [
    // {
    //     path: '',
    //     loadChildren: '../public/public.module#PublicModule'
    // },
    {
        path: '',
       
        component: MasterComponent,
        children: [
           
            {
                 path: '', redirectTo: 'home', pathMatch: 'full' },
               // loadChildren: 'src/app/components/homepage/_modules#HomepageModule'
            
            {
                path: 'home',
                loadChildren: 'src/app/components/homepage/_modules#HomepageModule'
            },
            {
                path: 'about',
                loadChildren: 'src/app/components/about/_modules#AboutModule'
            },
            {
                path: 'managed-it-services',
                loadChildren: 'src/app/components/manage-it-services/_modules#ManageItServicesModule'
            },
            {
                path: 'managed-it-services/networking-solutions',
                loadChildren: 'src/app/components/manage-it-services/networking-solutions/_modules#NetworkingSolutionsModule'
            },
            {
                path: 'managed-it-services/data-security-management',
                loadChildren: 'src/app/components/manage-it-services/data-security-management/_modules#DataSecurityManagementModule'
            },
            {
                path: 'managed-it-services/virtualization-solutions',
                loadChildren: 'src/app/components/manage-it-services/virtualization-solutions/_modules#VirtualizationSolutionsModule'
            },
            {
                path: 'managed-it-services/regulatory-compliance-assistance',
                loadChildren: 'src/app/components/manage-it-services/regulatory-compliance-assistance/_modules#RegulatoryComplianceAssistanceModule'
            },
            {
                path: 'managed-it-services/computing-solutions',
                loadChildren: 'src/app/components/manage-it-services/computing-solutions/_modules#ComputingSolutionsModule'
            },
            {
                path: 'managed-it-services/email-productivity-services',
                loadChildren: 'src/app/components/manage-it-services/email-productivity-services/_modules#EmailProductivityServicesModule'
            },
            {
                path: 'managed-it-services/telephone-services',
                loadChildren: 'src/app/components/manage-it-services/telephone-services/_modules#TelephoneServicesModule'
            },
            {
                path: 'managed-it-services/copiers-printers-scanners',
                loadChildren: 'src/app/components/manage-it-services/copiers-printers-scanners/_modules#CopiersPrintersScannersModule'
            },
            {
                path: 'cloud-services',
                loadChildren: 'src/app/components/cloud-services/_modules#CloudServicesModule'
            },
            {
                path: 'cloud-services/web-services',
                loadChildren: 'src/app/components/cloud-services/web-services/_modules#WebServicesModule'
            },
            {
                path: 'cloud-services/cloud-backup',
                loadChildren: 'src/app/components/cloud-services/backup/_modules#BackupModule'
            },
            {
                path: 'cloud-services/file-sharing',
                loadChildren: 'src/app/components/cloud-services/file-sharing/_modules#FileSharingModule'
            },
            {
                path: 'cloud-services/office-365',
                loadChildren: 'src/app/components/cloud-services/office-365/_modules#Office365Module'
            },
            {
                path: 'merchant-services',
                loadChildren: 'src/app/components/merchant-services/_modules#MerchantServicesModule'
            },
            {
                path: 'vcto',
                loadChildren: 'src/app/components/vcto/_modules#VCTOModule'
            },
            {
                path: 'clients',
                loadChildren: 'src/app/components/clients/_modules#ClientsModule'
            },
            {
                path: 'partners',
                loadChildren: 'src/app/components/partners/_modules#PartnersModule'
            },
            {
                path: 'contact',
                loadChildren: 'src/app/components/contact/_modules#ContactModule'
            },
            {
                path: 'support',
                loadChildren: 'src/app/components/support/_modules#SupportModule'
            },
            {
                path: 'cloud-workstation',
                loadChildren: 'src/app/components/cloud-workstation/_modules#CloudWorkstationModule'
            },
            {
                path: 'remote-workstation',
                loadChildren: 'src/app/components/remote-workstation/_modules#RemoteWorkstationModule'
            },
            {
                path: 'resources',
                loadChildren: 'src/app/components/resources/_modules#ResourcesModule'
            },
            {
                path: 'consultation',
                loadChildren: 'src/app/components/consultation/_modules#ConsultationModule'
            },
            {
                path: 'order-form',
                loadChildren: 'src/app/components/order-form/_modules#OrderFormModule'
            },
            {
                path: 'order-placed',
                loadChildren: 'src/app/components/order-placed/_modules#OrderPlacedModule'
            },
            {
                path: 'privacy-policy',
                loadChildren: 'src/app/components/privacy-policy/_modules#PrivacyPolicyModule'
            },
            {
                path: 'terms-and-conditions',
                loadChildren: 'src/app/components/terms-and-conditions/_modules#TermsAndConditionsModule'
            },
            
        ],

    }


];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        MatSharedModule,
        MDBBootstrapModule.forRoot()
    ],

    declarations: [
        MasterComponent
    ],
    providers: []
})
export class MasterModule { }
